import { createStore } from 'vuex'

import router from '../router'

import storesApi from '../api/storesApi'
import ordersApi from '@/api/ordersApi'
import customersApi from '@/api/customersApi'
import brandingDefault from '@/assets/defaults/branding.json'

export default createStore({
  state: {
    storeConfig: null,
    selectedService:
      localStorage.getItem('selectedServiceData') && localStorage.getItem('selectedServiceData') != 'undefined'
        ? JSON.parse(localStorage.getItem('selectedServiceData'))
        : null,
    loggedIn: false,
    selectedStore: null,
    orderData: null,
    phoneVerified: localStorage.getItem('phoneVerified') == 'true' ? true : false,
    emailVerified: localStorage.getItem('emailVerified') == 'true' ? true : false,
    selectedStoreDays: JSON.parse(localStorage.getItem('selectedStoreDays')) || null,
    selectedDay: JSON.parse(localStorage.getItem('selectedDay')) || null,
    selectedHour: JSON.parse(localStorage.getItem('selectedHour')) || null,
    selectedPaymentMethod: JSON.parse(localStorage.getItem('selectedPaymentMethod')) || null,
    buttonLoader: false,
    userEmail: localStorage.getItem('userEmail') || null,
    dayAndTimeSelected: localStorage.getItem('dayAndTimeSelected') == 'true' ? true : false,
    customerData: null,
    toaster: [],
    itemToaster: {
      message: '',
      show: false,
    },
    selectedAddress: JSON.parse(localStorage.getItem('selectedAddress')) || null,
    branding: null,
    cartIsOpen: false,
    cookies: JSON.parse(localStorage.getItem('cookies')) || {
      __ac: '',
      'NovaDine.session': '',
      ROUTE_ID: '',
    },
    copperDetails: null,
    cartButtonTop: null,
    cartButtonLeft: null,
    mapType: 'GOOGLE',
    autoCompleteType: 'RADAR',
  },
  mutations: {
    SET_STORE_CONFIG(state, storeConfig) {
      if (storeConfig.loyalty) {
        let tiers = storeConfig.loyalty.programs_information
        // sort the tiers by threshold from lowest to highest
        tiers.sort((a, b) => a.threshold - b.threshold)
        storeConfig.loyalty.programs_information = tiers
      }
      localStorage.setItem('chainName', storeConfig.chain_name)
      state.storeConfig = storeConfig
    },
    SET_SELECTED_SERVICE(state, selectedService) {
      state.selectedService = selectedService
      // set the selected service in local storage
      localStorage.setItem('selectedServiceData', JSON.stringify(selectedService))
    },
    SET_LOGGED_IN(state, loggedIn) {
      localStorage.setItem('loggedIn', loggedIn)
      state.loggedIn = loggedIn
    },
    SET_SELECTED_STORE(state, selectedStore) {
      state.selectedStore = selectedStore
    },
    SET_ORDER_DATA(state, orderData) {
      state.orderData = orderData
    },
    SET_PHONE_VERIFIED(state, phoneVerified) {
      state.phoneVerified = phoneVerified
    },
    SET_EMAIL_VERIFIED(state, emailVerified) {
      state.emailVerified = emailVerified
    },
    SET_SELECTED_STORE_DAYS(state, selectedStoreDays) {
      state.selectedStoreDays = selectedStoreDays
    },
    SET_SELECTED_DAY(state, selectedDay) {
      state.selectedDay = selectedDay
    },
    SET_SELECTED_HOUR(state, selectedHour) {
      state.selectedHour = selectedHour
    },
    SET_SELECTED_PAYMENT_METHOD(state, selectedPaymentMethod) {
      state.selectedPaymentMethod = selectedPaymentMethod
    },
    SET_BUTTON_LOADER(state, buttonLoader) {
      state.buttonLoader = buttonLoader
    },
    SET_USER_EMAIL(state, userEmail) {
      state.userEmail = userEmail
    },
    SET_DAY_AND_TIME_SELECTED(state, dayAndTimeSelected) {
      state.dayAndTimeSelected = dayAndTimeSelected
    },
    SET_CUSTOMER_DATA(state, customerData) {
      state.customerData = customerData
    },
    SET_TOASTER(state, payload) {
      payload.time = Date().now
      state.toaster.push(payload)
      // after 3 seconds remove the toaster
      setTimeout(() => {
        state.toaster.shift()
      }, payload.time || 3000)
    },
    SET_ITEM_TOASTER(state, payload) {
      state.itemToaster = payload
      setTimeout(() => {
        state.itemToaster.show = false
      }, payload.time || 3000)
    },
    SET_SELECTED_ADDRESS(state, selectedAddressId) {
      state.selectedAddress = selectedAddressId
    },
    SET_BRANDING(state, payload) {
      state.branding = payload
    },
    SET_BRANDING_DEFAULT(state, payload) {
      if (payload.key.includes('.')) {
        let keys = payload.key.split('.')
        state.branding[keys[0]][keys[1]] = payload.value
      } else {
        state.branding[payload.key] = payload.value
      }
    },
    SET_CART_IS_OPEN(state, payload) {
      state.cartIsOpen = payload
    },
    SET_COOKIES(state, payload) {
      if (payload) {
        if (payload.__ac) {
          state.cookies.__ac = payload.__ac
        }
        if (payload['NovaDine.session']) {
          state.cookies['NovaDine.session'] = payload['NovaDine.session']
        }
        if (payload.ROUTE_ID) {
          state.cookies.ROUTE_ID = payload.ROUTE_ID
        }
        localStorage.setItem('cookies', JSON.stringify(state.cookies))
      }
    },
    RESET_COOKIES(state) {
      state.cookies = { __ac: '', 'NovaDine.session': '', ROUTE_ID: '' }
      localStorage.setItem('cookies', JSON.stringify(state.cookies))
    },
    SET_COPPER_DETAILS(state, payload) {
      state.copperDetails = payload
    },
    SET_CART_BUTTON_POSITION(state, payload) {
      state.cartButtonTop = payload.top
      state.cartButtonLeft = payload.left
    },
    SET_AUTO_COMPLETE_TYPE(state, autoCompleteType) {
      state.autoCompleteType = autoCompleteType;
    },
    SET_LOYALTY_BASE_URL(state, newUrl) {
      state.loyaltyBaseUrl = newUrl;
    },
    SET_LOYALTY_CLIENT_ID(state, newUrl) {
      state.loyaltyClientId = newUrl;
    }
  },
  actions: {
    getStoreConfig({ commit, dispatch }) {
      if (this.state.storeConfig) return
      storesApi
        .getConfiguration()
        .then((response) => {
          const loyaltyBaseUrl = response?.data?.data?.foreign_auth_providers?.okta?.issuer_url
          const loyaltyClientId = response?.data?.data?.foreign_auth_providers?.okta?.client_id
          commit('SET_LOYALTY_BASE_URL', loyaltyBaseUrl);
          commit('SET_LOYALTY_CLIENT_ID', loyaltyClientId);
          // go through the services and find the first one that is not require address and is visible
          let service = response.data.data.services.find((service) => !service.requires_address && service.is_visible)
          if (this.state?.branding?.display_options?.make_default_service_type_first && service) {
            // move it to the top of the list
            response.data.data.services.splice(response.data.data.services.indexOf(service), 1)
            response.data.data.services.unshift(service)
          }
          commit('SET_STORE_CONFIG', response.data.data)
          commit('SET_COOKIES', response.data.cookies)
          if (localStorage.getItem('selectedServiceId') === null) {
            let routeName = router.currentRoute.value.name
            if (
              (service && (routeName == 'HomeView' || routeName == 'PasswordResetView')) ||
              routeName == 'ConfirmationView'
            ) {
              localStorage.setItem('selectedServiceId', service.service_type_id)
              commit('SET_SELECTED_SERVICE', service)
            }
            // if there is no service that does not require address, set the first service as the selected service
            if (!service) {
              localStorage.setItem('selectedServiceId', response.data.data.services[0].service_type_id)
              commit('SET_SELECTED_SERVICE', response.data.data.services[0])
            }
          }
          dispatch('getSelectedService')
          if (!response.data.data.services[0].requires_address) {
            dispatch('getSelectedStore')
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getSelectedStore({ commit }) {
      if (this.state.selectedStore) return
      let storeId = localStorage.getItem('selectedStoreId')
      let payload = {
        service_schedule: true,
        service_type_id: localStorage.getItem('selectedServiceId'),
      }
      if (!storeId) return
      storesApi
        .getStore(storeId, payload)
        .then((response) => {
          commit('SET_SELECTED_STORE', response.data.data)
          commit('SET_COOKIES', response.data.cookies)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getSelectedService({ commit }) {
      if (this.state.selectedService) return
      let selectedServiceId = localStorage.getItem('selectedServiceId')
      let selectedService = this.state.storeConfig.services.find(
        (service) => service.service_type_id == selectedServiceId
      )
      commit('SET_SELECTED_SERVICE', selectedService)
    },
    getOrderData({ commit }) {
      if (this.state.orderData || localStorage.getItem('orderId') === null) return
      let orderId = localStorage.getItem('orderId')
      ordersApi
        .getOrder(orderId)
        .then((response) => {
          commit('SET_ORDER_DATA', response.data.data)
          commit('SET_COOKIES', response.data.cookies)
        })
        .catch((error) => {
          console.log(error)
          if (error.response.data.status == 403) {
            let toasterData = {
              show: true,
              message: 'Your session has expired. Please log in again.',
            }
            commit('SET_TOASTER', toasterData)

            commit('SET_LOGGED_IN', false)
            commit('SET_ORDER_DATA', null)
            localStorage.removeItem('customerId')
            localStorage.removeItem('orderId')
            localStorage.removeItem('selectedTip')
            localStorage.removeItem('dayAndTimeSelected')
            localStorage.removeItem('auth')
            localStorage.removeItem('phoneVerified')
            localStorage.removeItem('emailVerified')
            localStorage.removeItem('newCardData')
            localStorage.removeItem('otherTipAmount')
            commit('RESET_COOKIES')
            commit('SET_SELECTED_COMPONENT', 'ProfileView')
            commit('SET_DAY_AND_TIME_SELECTED', false)
            commit('SET_CUSTOMER_DATA', null)

            router.push('/')
          }
        })
    },
    getCustomerData({ commit }) {
      if (this.state.customerData && this.state.loggedIn) return
      customersApi
        .getCustomerInfo()
        .then((response) => {
          commit('SET_CUSTOMER_DATA', response.data.data)
          commit('SET_COOKIES', response.data.cookies)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    async getBranding({ commit, dispatch }) {
      if (this.state.branding) return
      await storesApi
        .getBranding()
        .then((response) => {
          commit('SET_BRANDING', response.data.data.data)
          // if data is missing, set it to the default
          dispatch('setMissingBranding')
        })
        .catch((error) => {
          console.log(error)
          commit('SET_BRANDING', brandingDefault)
        })
    },
    setMissingBranding({ commit }) {
      const propertiesToUpdate = [
        { key: 'primary_color', value: brandingDefault.primary_color },
        { key: 'primary_text_color', value: brandingDefault.primary_text_color },
        { key: 'background_color', value: brandingDefault.background_color },
        { key: 'gray_color', value: brandingDefault.gray_color },
        { key: 'passive_color', value: brandingDefault.passive_color },
        { key: 'logo_url', value: brandingDefault.logo_url },
        { key: 'hero_image_url', value: brandingDefault.hero_image_url },
        { key: 'gift_card_image', value: brandingDefault.gift_card_image },
        { key: 'favicon_url', value: brandingDefault.favicon_url },
        { key: 'menu_logo_url', value: brandingDefault.menu_logo_url },
        { key: 'fonts', value: brandingDefault.fonts },
        { key: 'title', value: brandingDefault.title },
        { key: 'description', value: brandingDefault.description },
        { key: 'markers', value: brandingDefault.markers },
        { key: 'map_style', value: brandingDefault.map_style },
        { key: 'links', value: brandingDefault.links },
        { key: 'category_placement', value: brandingDefault.category_placement },
        { key: 'display_options.rewards', value: brandingDefault.display_options.rewards },
        { key: 'display_options.category_image', value: brandingDefault.display_options.category_image },
        { key: 'display_options.icons', value: brandingDefault.display_options.icons },
        { key: 'display_options.hide_order_note', value: brandingDefault.display_options.hide_order_note },
        { key: 'display_options.hide_item_note', value: brandingDefault.display_options.hide_item_note },
        { key: 'display_options.display_rewards_page', value: brandingDefault.display_options.display_rewards_page },
        {
          key: 'display_options.display_promos_and_rewards_on_cart',
          value: brandingDefault.display_options.display_promos_and_rewards_on_cart,
        },
        {
          key: 'display_options.display_partial_payments',
          value: brandingDefault.display_options.display_partial_payments,
        },
        { key: 'display_options.template', value: brandingDefault.display_options.template },
        {
          key: 'display_options.display_order_tracking',
          value: brandingDefault.display_options.display_order_tracking,
        },
        { key: 'display_options.button_radius', value: brandingDefault.display_options.button_radius },
        {
          key: 'display_options.display_store_images_as_hero',
          value: brandingDefault.display_options.display_store_images_as_hero,
        },
        { key: 'display_options.hide_include_utensils', value: brandingDefault.display_options.hide_include_utensils },
        {
          key: 'display_options.display_delivery_instructions',
          value: brandingDefault.display_options.display_delivery_instructions,
        },
      ]

      propertiesToUpdate.forEach((property) => {
        if (property.key.includes('.')) {
          let keys = property.key.split('.')
          if (!this.state.branding[keys[0]]) {
            commit('SET_BRANDING_DEFAULT', { key: 'display_options', value: {} })
          }
          if (!this.state.branding[keys[0]][keys[1]]) {
            commit('SET_BRANDING_DEFAULT', { key: property.key, value: property.value })
          }
        } else {
          if (!this.state.branding[property.key]) {
            commit('SET_BRANDING_DEFAULT', { key: property.key, value: property.value })
          }
        }
      })
    },
    setFraudSessionID() {
      let merchantId = localStorage.getItem('merchantId')
      if (merchantId) {
        window.Finix.Auth('live', merchantId, (sessionKey) => {
          localStorage.setItem('fraudSessionId', sessionKey)
        });
      }
      if (sessionStorage.getItem('fraudSessionID')) return
    },
  },
  modules: {},
})
